import { FC, useState, useCallback, useEffect } from 'react';
import { decode } from 'jsonwebtoken';
import { createContext } from 'use-context-selector';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import useBank from '@hooks/bank';

import bbankApi from '../services/bbankApi';
import {
  IAuthContext,
  IResponseType,
  IAuthState,
  IPayloadToken,
} from './interfaces/auth';
import { useToast } from '../hooks/toast';

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const { addToast } = useToast();
  const { push } = useHistory();
  const { handleLogOut } = useBank();

  const [data, setData] = useState<IAuthState>({} as IAuthState);
  const [loading, setLoading] = useState(true);
  const [completedData, setCompletedData] = useState(false);

  const signOut = useCallback(() => {
    handleLogOut();
    localStorage.removeItem('@Bbanking:Auth');
    setData({} as IAuthState);
    push('/');
  }, [handleLogOut, push]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedSession = localStorage.getItem('@Bbanking:Auth');

        if (!storedSession) {
          setData({} as IAuthState);
          return;
        }

        const parsedSession = JSON.parse(storedSession);
        const { token } = parsedSession;

        if (token) {
          await bbankApi.post('/session/verify', { token });

          bbankApi.defaults.headers.authorization = `Bearer ${token}`;
          const response = await bbankApi.get(`/users/me`);

          console.log(response.data.status);

          if (response.data.status === 'inativo') {
            addToast({
              title: 'Cadastro inativo!',
              type: 'info',
              message:
                'O seu cadastro foi desativado. Por favor, entre em contato com a administração do sistema e informe ocorrido!',
            });
            signOut();
          } else {
            setData(parsedSession);
          }
        } else {
          signOut();
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        signOut();
      }
    };

    const debouncedFetchUserData = debounce(fetchUserData, 1000);

    const listenForUserActivity = () => {
      window.addEventListener('click', debouncedFetchUserData);
      window.addEventListener('scroll', debouncedFetchUserData);
      window.addEventListener('popstate', debouncedFetchUserData);
    };

    listenForUserActivity();

    return () => {
      window.removeEventListener('click', debouncedFetchUserData);
      window.removeEventListener('scroll', debouncedFetchUserData);
      window.removeEventListener('popstate', debouncedFetchUserData);
    };
  }, [signOut, setData, addToast]);

  useEffect(() => {
    async function login() {
      try {
        const storedSession = localStorage.getItem('@Bbanking:Auth');

        if (!storedSession) {
          setData({} as IAuthState);
          return;
        }

        const parsesStoredSession = JSON.parse(storedSession);

        if (parsesStoredSession.token) {
          await bbankApi.post('/session/verify', {
            token: parsesStoredSession.token,
          });

          bbankApi.defaults.headers.authorization = `Bearer ${parsesStoredSession.token}`;

          const completed = Object.entries(parsesStoredSession.user).every(
            ([key, value]) => {
              if (key === 'nickname' || key === 'indication') {
                return true;
              }

              return value !== null;
            },
          );

          setCompletedData(true);
          setData(parsesStoredSession);
        }
      } catch {
        addToast({
          title: 'Sessão expirada!',
          type: 'info',
          message: 'Faça login novamente!',
        });

        signOut();
      } finally {
        setLoading(false);
      }
    }

    const timer = setTimeout(() => {
      login();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [addToast, signOut]);

  const signIn = useCallback(
    async (
      document: string,
      password: string,
    ): Promise<{ ok: boolean; type?: 'inativo' | 'pendente' }> => {
      const response = await bbankApi.post<IResponseType>('/session', {
        document,
        password,
      });

      const { token, user, system_balance } = response.data;

      if (user.status === 'pendente') {
        return {
          ok: false,
          type: 'pendente',
        };
      }

      if (user.status === 'inativo') {
        return {
          ok: false,
          type: 'inativo',
        };
      }

      const { role } = decode(token) as IPayloadToken;

      const sessionData = {
        token,
        user: {
          id: user.id,
          email: user.email,
          name: user.name,
          zipcode: user.zipcode,
          state: user.state,
          city: user.city,
          address: user.address,
          phone: user.phone,
          document: user.document,
          role,
          token,
          system_balance,
        },
      };

      bbankApi.defaults.headers.authorization = `Bearer ${sessionData.token}`;

      setData({
        token: sessionData.token,
        user: {
          ...sessionData.user,
          role,
        },
      });

      const completed = Object.entries(user).every(([key, value]) => {
        if (key === 'nickname' || key === 'indication') {
          return true;
        }

        return value !== null;
      });

      setCompletedData(true);

      localStorage.setItem('@Bbanking:Auth', JSON.stringify(sessionData));

      return {
        ok: true,
      };
    },
    [],
  );

  const handleCompleteData = useCallback(() => {
    setCompletedData(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        user: data.user,
        loading,
        signOut,
        completedData,
        handleCompleteData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
